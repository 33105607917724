import "./App.css";
import Introduction from "./component/introduction";

function App() {
  return (
    <div>
      <Introduction
        title={"Selamat datang di Web kami"}
        description={"Disini kita akan belajar react js"}
      />
      <Introduction
        title={"Title Kedua OKE"}
        description={"Description kedua"}
      />

      <Introduction
        title={"Title Kedua OKE"}
        description={"Description kedua"}
      />

      <Introduction
        title={"Title Kedua OKE"}
        description={"Description kedua"}
      />
    </div>
  );
}

export default App;
